<template>
    <div
        ref="modal"
        class="modal fade"
        :id="id"
        tabindex="-1"
        style="z-index: 1000000"
    >
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <label :for="`${id}${field}Input`" class="form-label">
                        {{ title }}
                    </label>
                    <input
                        :type="inputTypeValue"
                        ref="input"
                        :placeholder="title"
                        :id="`${id}${field}Input`"
                        class="form-control"
                        v-model="editing"
                        @keydown.enter="clickSaveButton()"
                    />
                </div>
                <div class="border-top">
                    <div class="d-flex flex-row px-2">
                        <a
                            @click="logout"
                            data-bs-dismiss="modal"
                            class="w-50 border-end py-2"
                            style="font-weight: 500"
                        >
                            <div class="text-center">
                                {{ t('misc.modal.cancel') }}
                            </div>
                        </a>
                        <a
                            class="w-50 py-2"
                            style="font-weight: 600"
                            :id="id + 'SaveButton'"
                            data-bs-dismiss="modal"
                            @click="save"
                        >
                            <div class="text-center">
                                {{ t('misc.modal.save') }}
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import axios from 'axios';
import { toRefs, defineProps, ref, defineEmits, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const props = defineProps(['id', 'title', 'initial', 'field', 'inputType']);
const emit = defineEmits(['updateUser']);
const { id, title, initial, field, inputType } = toRefs(props);
const editing = ref(initial.value);
const inputTypeValue = ref(null);
const input = ref(null);
const modal = ref(null);

if (inputType == null) {
    inputTypeValue.value = 'text';
} else {
    inputTypeValue.value = inputType.value;
}

function clickSaveButton() {
    const element = document.querySelector(`#${id.value}SaveButton`);
    element.click();
}

onMounted(() => {
    modal.value.addEventListener('shown.bs.modal', function () {
        input.value.focus();
    });
});

async function save() {
    let data = {};
    data[field.value] = editing.value;
    await axios.post('user/update', data);
    emit('updateUser');
}
</script>

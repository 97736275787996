<template>
    <div
        ref="resetPasswordModal"
        class="modal fade"
        :id="id"
        tabindex="-1"
        style="z-index: 1000000"
    >
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <button
                    class="d-none"
                    ref="dismissResetPasswordModal"
                    id="DismissResetPasswordModal"
                    data-bs-dismiss="modal"
                ></button>
                <div class="modal-body text-center">
                    <div class="mb-3">
                        <label for="CurrentPasswordInput" class="form-label">
                            {{
                                t(
                                    'pages.more.myAccount.resetPasswordModal.currentPassword'
                                )
                            }}
                        </label>
                        <input
                            type="text"
                            placeholder="Current password"
                            id="CurrentPasswordInput"
                            ref="currentPasswordInput"
                            class="form-control"
                            :class="{ 'is-invalid': error }"
                            v-model="currentPassword"
                        />
                    </div>
                    <div class="">
                        <label for="NewPasswordInput" class="form-label">
                            {{
                                t(
                                    'pages.more.myAccount.resetPasswordModal.newPassword'
                                )
                            }}
                        </label>
                        <input
                            type="text"
                            :placeholder="
                                t(
                                    'pages.more.myAccount.resetPasswordModal.newPassword'
                                )
                            "
                            id="NewPasswordInput"
                            class="form-control"
                            :class="{ 'is-invalid': error }"
                            v-model="newPassword"
                            @keydown.enter="clickSaveButton()"
                        />
                        <div class="text-danger mt-2 text-start">
                            {{ errorMessage }}
                        </div>
                    </div>
                </div>
                <div class="border-top">
                    <div class="d-flex flex-row px-2">
                        <a
                            @click="logout"
                            data-bs-dismiss="modal"
                            class="w-50 border-end py-2"
                            style="font-weight: 500"
                        >
                            <div class="text-center">
                                {{ t('misc.modal.cancel') }}
                            </div>
                        </a>
                        <a
                            class="w-50 py-2"
                            style="font-weight: 600"
                            :id="id + 'SaveButton'"
                            @click="save"
                        >
                            <div class="text-center">
                                {{ t('misc.modal.save') }}
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import axios from 'axios';
import { useI18n } from 'vue-i18n';
import { toRefs, defineProps, ref, onMounted } from 'vue';

const props = defineProps(['id']);
const { id } = toRefs(props);

const { t } = useI18n();

const error = ref(false);
const newPassword = ref(null);
const errorMessage = ref(null);
const currentPassword = ref(null);
const resetPasswordModal = ref(null);
const currentPasswordInput = ref(null);
const dismissResetPasswordModal = ref(null);

function clickSaveButton() {
    const element = document.querySelector(`#${id.value}SaveButton`);
    element.click();
}

onMounted(() => {
    resetPasswordModal.value.addEventListener('shown.bs.modal', function () {
        currentPasswordInput.value.focus();
    });
});

document.addEventListener('DOMContentLoaded', () => {});

async function save() {
    error.value = false;
    try {
        // make sure that password is longer than 8 chars
        if (newPassword.value != null && newPassword.value.length >= 8) {
            await axios.post('user/update/password', {
                current_password: currentPassword.value,
                new_password: newPassword.value,
            });
            error.value = false;
            dismissResetPasswordModal.value.click();
        } else {
            error.value = true;
            errorMessage.value = t(
                'pages.more.myAccount.resetPasswordModal.passwordLengthError'
            );
        }
    } catch (err) {
        console.error(err);
        error.value = true;
        errorMessage.value = err.response.data.message;
    }
}
</script>

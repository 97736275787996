<style scoped>
.profile-img-rounded {
    border-radius: 100%;
    aspect-ratio: 1/1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
}
</style>
<template>
    <SingleInputModal
        @update-user="fetchUser"
        id="EmailModal"
        :title="t('pages.more.myAccount.email')"
        field="email"
        :initial="user.email"
    />
    <ResetPasswordModal id="ResetPasswordModal" />
    <SingleInputModal
        @update-user="fetchUser"
        id="FirstNameModal"
        :title="t('pages.more.myAccount.firstName')"
        field="first_name"
        :initial="user.first_name"
    />
    <SingleInputModal
        @update-user="fetchUser"
        id="LastNameModal"
        :title="t('pages.more.myAccount.lastName')"
        field="last_name"
        :initial="user.last_name"
    />
    <SingleInputModal
        @update-user="fetchUser"
        id="PhoneModal"
        :title="t('pages.more.myAccount.phone')"
        field="phone"
        input-type="tel"
        :initial="user.phone"
    />
    <SingleInputModal
        @update-user="fetchUser"
        id="SecondaryPhoneModal"
        :title="t('pages.more.myAccount.secondaryPhone')"
        field="secondary_phone"
        input-type="tel"
        :initial="user.secondary_phone"
    />
    <SingleInputModal
        @update-user="fetchUser"
        id="AddressModal"
        :title="t('pages.more.myAccount.address')"
        field="address"
        :initial="user.address"
    />
    <SingleInputModal
        @update-user="fetchUser"
        id="AdditionalAddressModal"
        :title="t('pages.more.myAccount.additionalAddress')"
        field="additional_address"
        :initial="user.additional_address"
    />
    <SingleInputModal
        @update-user="fetchUser"
        id="PostcodeModal"
        :title="t('pages.more.myAccount.postcode')"
        field="postcode"
        input-type="number"
        :initial="user.postcode"
    />
    <SingleInputModal
        @update-user="fetchUser"
        id="CityModal"
        :title="t('pages.more.myAccount.city')"
        field="city"
        :initial="user.city"
    />
    <SingleInputModal
        @update-user="fetchUser"
        id="CountryModal"
        :title="t('pages.more.myAccount.country')"
        field="country"
        :initial="user.country"
    />
    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <div class="d-flex flex-row align-items-center mb-3" style="gap: 0px">
            <BackButton />
            <h1 class="m-0 text-light">
                {{ t('pages.more.myAccount.myAccount') }}
            </h1>
        </div>
        <div class="card border-0 rounded-0 rounded-top mb-3 shadow-sm">
            <input
                ref="photoUploadInput"
                class="d-none"
                type="file"
                accept="image/*"
                @change="handleFileUpload()"
            />
            <div
                class="p-3 d-flex flex-column align-items-center rounded-top"
                @click="photoUploadInput.click()"
            >
                <div
                    class="profile-img-rounded mb-2"
                    :style="{ 'background-image': `url('${userPhotoLink}')` }"
                ></div>
                <span v-if="uploadPercentage == 0 || uploadPercentage == 100">
                    <p class="text-muted m-0">
                        {{ t('pages.more.myAccount.tapToChangePhoto') }}
                    </p>
                </span>
                <span class="text-center" v-else>
                    <p class="mb-1">{{ t('misc.uploading') }}</p>
                    <progress :value="uploadPercentage" max="100" />
                </span>
            </div>

            <ul class="list-group">
                <li
                    class="list-group-item rounded-0 tap-react d-flex flex-row justify-content-between"
                    style="gap: 0.5rem"
                    data-bs-toggle="modal"
                    data-bs-target="#FirstNameModal"
                >
                    {{ t('pages.more.myAccount.firstName') }}
                    <div>
                        <span
                            class="text-muted"
                            style="overflow: hidden; text-overflow: ellipsis"
                        >
                            {{ user.first_name }}
                        </span>
                        <i class="ms-2 text-muted fa fa-chevron-right"></i>
                    </div>
                </li>
                <li
                    class="list-group-item tap-react d-flex flex-row justify-content-between"
                    style="gap: 0.5rem"
                    data-bs-toggle="modal"
                    data-bs-target="#LastNameModal"
                >
                    {{ t('pages.more.myAccount.lastName') }}
                    <div>
                        <span
                            class="text-muted"
                            style="overflow: hidden; text-overflow: ellipsis"
                        >
                            {{ user.last_name }}
                        </span>
                        <i class="ms-2 text-muted fa fa-chevron-right"></i>
                    </div>
                </li>
                <li
                    class="list-group-item tap-react d-flex flex-row justify-content-between"
                    style="gap: 0.5rem"
                    data-bs-toggle="modal"
                    data-bs-target="#EmailModal"
                >
                    {{ t('pages.more.myAccount.email') }}

                    <div>
                        <span
                            class="text-muted"
                            style="overflow: hidden; text-overflow: ellipsis"
                        >
                            {{ user.email }}
                        </span>
                        <i class="ms-2 text-muted fa fa-chevron-right"></i>
                    </div>
                </li>
                <li
                    class="list-group-item tap-react d-flex flex-row justify-content-between"
                    style="gap: 0.5rem"
                    data-bs-toggle="modal"
                    data-bs-target="#ResetPasswordModal"
                >
                    {{ t('pages.login.password') }}

                    <div>
                        <span
                            class="text-muted"
                            style="overflow: hidden; text-overflow: ellipsis"
                        >
                            •••••••••
                        </span>
                        <i class="ms-2 text-muted fa fa-chevron-right"></i>
                    </div>
                </li>
                <li
                    class="list-group-item tap-react d-flex flex-row justify-content-between"
                    style="gap: 0.5rem"
                    data-bs-toggle="modal"
                    data-bs-target="#PhoneModal"
                >
                    {{ t('pages.more.myAccount.phone') }}
                    <div>
                        <span class="text-muted" v-if="!user.phone">{{
                            t('misc.none')
                        }}</span>
                        <span
                            class="text-muted"
                            style="overflow: hidden; text-overflow: ellipsis"
                            v-else
                        >
                            {{ user.phone }}
                        </span>
                        <i class="ms-2 text-muted fa fa-chevron-right"></i>
                    </div>
                </li>
                <li
                    class="list-group-item tap-react d-flex flex-row justify-content-between"
                    style="gap: 0.5rem"
                    data-bs-toggle="modal"
                    data-bs-target="#SecondaryPhoneModal"
                >
                    {{ t('pages.more.myAccount.secondaryPhone') }}
                    <div>
                        <span class="text-muted" v-if="!user.secondary_phone">{{
                            t('misc.none')
                        }}</span>
                        <span
                            class="text-muted"
                            style="overflow: hidden; text-overflow: ellipsis"
                            v-else
                        >
                            {{ user.secondary_phone }}
                        </span>
                        <i class="ms-2 text-muted fa fa-chevron-right"></i>
                    </div>
                </li>
            </ul>
        </div>

        <ul class="list-group mb-3 shadow-sm">
            <li
                class="list-group-item tap-react d-flex flex-row justify-content-between"
                style="gap: 0.5rem"
                data-bs-toggle="modal"
                data-bs-target="#AddressModal"
            >
                {{ t('pages.more.myAccount.address') }}
                <div>
                    <span class="text-muted" v-if="!user.address">{{
                        t('misc.none')
                    }}</span>
                    <span
                        class="text-muted"
                        style="overflow: hidden; text-overflow: ellipsis"
                        v-else
                    >
                        {{ user.address }}
                    </span>
                    <i class="ms-2 text-muted fa fa-chevron-right"></i>
                </div>
            </li>
            <li
                class="list-group-item tap-react d-flex flex-row justify-content-between"
                style="gap: 0.5rem"
                data-bs-toggle="modal"
                data-bs-target="#AdditionalAddressModal"
            >
                {{ t('pages.more.myAccount.additionalAddress') }}
                <div>
                    <span class="text-muted" v-if="!user.additional_address">{{
                        t('misc.none')
                    }}</span>
                    <span
                        class="text-muted"
                        style="overflow: hidden; text-overflow: ellipsis"
                        v-else
                    >
                        {{ user.additional_address }}
                    </span>
                    <i class="ms-2 text-muted fa fa-chevron-right"></i>
                </div>
            </li>
            <li
                class="list-group-item tap-react d-flex flex-row justify-content-between"
                style="gap: 0.5rem"
                data-bs-toggle="modal"
                data-bs-target="#PostcodeModal"
            >
                {{ t('pages.more.myAccount.postcode') }}
                <div>
                    <span v-if="!user.postcode" class="text-muted">{{
                        t('misc.none')
                    }}</span>
                    <span
                        class="text-muted"
                        style="overflow: hidden; text-overflow: ellipsis"
                        v-else
                    >
                        {{ user.postcode }}
                    </span>
                    <i class="ms-2 text-muted fa fa-chevron-right"></i>
                </div>
            </li>
            <li
                class="list-group-item tap-react d-flex flex-row justify-content-between"
                style="gap: 0.5rem"
                data-bs-toggle="modal"
                data-bs-target="#CityModal"
            >
                {{ t('pages.more.myAccount.city') }}
                <div>
                    <span class="text-muted" v-if="!user.city">{{
                        t('misc.none')
                    }}</span>
                    <span
                        v-else
                        class="text-muted"
                        style="overflow: hidden; text-overflow: ellipsis"
                    >
                        {{ user.city }}
                    </span>
                    <i class="ms-2 text-muted fa fa-chevron-right"></i>
                </div>
            </li>
            <li
                class="list-group-item tap-react d-flex flex-row justify-content-between"
                style="gap: 0.5rem"
                data-bs-toggle="modal"
                data-bs-target="#CountryModal"
            >
                {{ t('pages.more.myAccount.country') }}
                <div>
                    <span class="text-muted" v-if="!user.country">{{
                        t('misc.none')
                    }}</span>
                    <span
                        v-else
                        class="text-muted"
                        style="overflow: hidden; text-overflow: ellipsis"
                    >
                        {{ user.country }}
                    </span>
                    <i class="ms-2 text-muted fa fa-chevron-right"></i>
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup>
import axios from 'axios';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user-store';
import { onMounted, ref, watch, inject } from 'vue';

import DefaultUserPhoto from '@/assets/user-placeholder.png';
import BackButton from '@/components/BackButton';
import SingleInputModal from './SingleInputModal.vue';
import ResetPasswordModal from './ResetPasswordModal.vue';

const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');

const { t } = useI18n();

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const photoUploadInput = ref(null);
const userPhotoLink = ref(null);
userPhotoLink.value = DefaultUserPhoto;

const uploadPercentage = ref(0);

watch(user, () => updateImage());

const handleFileUpload = async () => {
    let formData = new FormData();
    formData.append('photo', photoUploadInput.value.files[0]);
    await axios.post('user/photo', formData, {
        onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            );
            uploadPercentage.value = percentCompleted;
        },
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    userStore.fetchUser();
};

function updateImage() {
    if (user.value.passport_photo != null)
        userPhotoLink.value =
            process.env.VUE_APP_BACKEND_STORAGE_URL + user.value.passport_photo;
}

// Syntactic sugar for view above
const fetchUser = () => userStore.fetchUser();

onMounted(() => updateImage());
</script>
